import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import { graphql } from 'gatsby'
import Image from 'gatsby-image'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Hero,
  Container,
  Heading,
  Icon,
  Level,
  Box,
  Section,
  Columns
} from 'react-bulma-components'
import Toggle from 'react-toggle'
import { Sun, Moon } from 'react-feather'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import './styles/index.scss'
import '../components/toggle/Toggle.scss'

// const { Fragment } = React

import packageJSON from '../../package.json'

import presets from '../utils/presets'

// const downloadURL = `/updates/Snipper-${packageJSON.latest}.dmg`

const FeatureSubheaderTitle = ({ children, ...props }) => (
  <strong
    css={{ fontSize: '1.1rem', marginTop: '1rem', display: 'block' }}
    {...props}
  >
    {children}
  </strong>
)

const FeatureSubheaderSubTitle = ({ children, ...props }) => (
  <span
    css={{
      color: '#797878',
      fontSize: '.95rem',
      marginTop: '.5rem',
      display: 'block'
    }}
    {...props}
  >
    {children}
  </span>
)
class IndexPage extends React.Component {
  state = {
    theme: 'light'
  }

  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }

  componentDidMount() {
    /*Paddle.Setup({
      vendor: 32731,
      completeDetails: true
    })*/
  }

  isLightTheme() {
    return this.state.theme === 'light'
  }

  getWindow() {
    const windowGlobal = typeof window !== 'undefined' && window
    return windowGlobal
  }

  trackInitiateCheckout() {
    const window = this.getWindow()
    if (window) {
      window.fbq && window.fbq('track', 'InitiateCheckout')
      window.ga &&
        window.ga('send', 'event', {
          eventCategory: 'home-page-button-large',
          eventAction: 'klik',
          eventLabel: event.target.href,
          transport: 'beacon'
        })
    }
  }

  onAppStoreClick(event) {
    event.preventDefault()
    this.trackInitiateCheckout()
    setTimeout(() => {
      window.open(
        'https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12',
        '_blank'
      )
    }, 200)
  }

  openCheckout = e => {
    e.preventDefault()
    this.trackInitiateCheckout()
    // Paddle.Checkout.open({ product: 536972, message: '1 license for 2 Macs' })
  }

  downloadLatest = e => {
    e.preventDefault()
    window.fbq && window.fbq('track', 'StartTrial')
  }

  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const heroProps = {
      fullheight: true,
      bold: true,
      light: true
    }

    return (
      <Layout
        location={location}
        title={siteTitle}
        className="index"
        snipperLogo={this.getImage('snipperLogo')}
        {...heroProps}
      >
        <SEO title={siteTitle} titleTemplate={() => `%s`} />
        <Hero size="fullheight">
          <Hero.Body
            className="is-fullheight white-body"
            style={{ paddingBottom: 0 }}
          >
            <Image
              fluid={this.getImage('backgroundImage')}
              style={{ position: 'inherit', overflow: 'none' }}
            />
            <Container className="has-text-centered">
              <div className="head-text-wrapper">
                <Heading
                  size={1}
                  renderAs="h1"
                  className="main-title"
                  css={{
                    [presets.Mobile]: {
                      fontSize: `1.5rem !important`
                    },
                    [presets.Tablet]: {
                      fontSize: `2.5rem !important`
                    },
                    [presets.Desktop]: {
                      fontSize: `3.5rem !important`
                    }
                  }}
                >
                  The next generation code snippets manager
                </Heading>
                <Heading
                  subtitle
                  size={5}
                  renderAs="h5"
                  itemProp="description"
                  className="desc"
                  css={{
                    [presets.Mobile]: {
                      fontSize: `1.1rem !important`
                    },
                    [presets.Tablet]: {
                      fontSize: `1.2rem !important`
                    },
                    [presets.Desktop]: {
                      fontSize: '1.25rem !important'
                    }
                  }}
                >
                  SnipperApp 2 is a native macOS snippets manager with modern,
                  lightweight GUI, syntax highlighting, and Markdown support
                  <br />
                  <span>♡ proudly made in Ukraine 🇺🇦 with Swift</span>
                </Heading>
                <a
                  href="https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12"
                  className="large-badge"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.onAppStoreClick.bind(this)}
                />
                <div className="app-meta">
                  <div className="is-size-7">
                    Latest version:{' '}
                    <span itemProp="softwareVersion">
                      {packageJSON.latest.version}
                    </span>{' '}
                    (
                    <time
                      itemProp="dateModified"
                      dateTime={packageJSON.latest.dateTime}
                    >
                      {packageJSON.latest.dateString}
                    </time>
                    )
                  </div>
                  <div className="is-size-7 required-os">
                    <span itemProp="operatingSystem">
                       macOS 10.14 or later required, 64-bit processor
                    </span>
                  </div>
                  <div className="is-size-7">
                    Category:{' '}
                    <span itemProp="applicationCategory">Developer Tools</span>
                    {' · '}
                    <span>Seller: </span>
                    <span
                      itemProp="author creator copyrightHolder"
                      itemScope
                      itemType="http://schema.org/Person"
                    >
                      <span itemProp="name">Andrii Teologov</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="columns is-vcentered">
                <div className="column"></div>
                <div className="column is-four-fifths">
                  <CSSTransition
                    in
                    classNames={{
                      appear: 'animated',
                      appearActive: 'bounceInRight'
                    }}
                    appear
                    enter={false}
                    timeout={2000}
                    unmountOnExit
                  >
                    <Image
                      key={Math.random()}
                      fluid={this.getImage(
                        this.isLightTheme() ? 'appLight' : 'appDark'
                      )}
                    />
                  </CSSTransition>
                  <link
                    itemProp="screenshot"
                    href="https://snipperapp.com/images/app-screenshot-2.png"
                  />
                </div>
                <div className="column"></div>
              </div>
              <Icon>
                <Sun />
              </Icon>
              <Toggle
                icons={false}
                checked={this.state.theme === 'dark'}
                onChange={e =>
                  this.setState({
                    theme: e.target.checked ? 'dark' : 'light'
                  })
                }
              />
              <Icon>
                <Moon />
              </Icon>
              <br />
              <a
                href="https://www.producthunt.com/posts/snipperapp-2?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-snipperapp-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=190744&theme=light&period=daily"
                  alt="SnipperApp 2 - Native macOS code snippets manager with syntax highlighting | Product Hunt Embed"
                  css={{ width: 250, marginTop: '2rem' }}
                  width="250px"
                  height="54px"
                />
              </a>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero>
          <Hero.Body>
            <Container className="has-text-centered">
              <Heading size={1} renderAs="h2">
                Built by developers for developers
              </Heading>
              <Heading subtitle size={5} renderAs="h5">
                Stay more organized and productive with powerful code editor
              </Heading>
              <Section style={{ padding: '1.5rem 1.5rem' }}>
                <Box
                  className="is-shadowless"
                  style={{ background: 'transparent' }}
                >
                  <Level>
                    <Level.Item>
                      <div>
                        <Heading renderAs="p">
                          <Image
                            alt="More than 120 languages"
                            fixed={
                              this.props.data.languagesCountImage
                                .childImageSharp.fixed
                            }
                            Tag="span"
                          />
                        </Heading>
                        <p>
                          Syntax highlighting <br /> for more than 120 languages
                        </p>
                      </div>
                      <Level.Item>
                        <div>
                          <Heading renderAs="p">
                            <Image
                              alt="Tab size customization"
                              fixed={
                                this.props.data.tabCustImage.childImageSharp
                                  .fixed
                              }
                              Tag="span"
                            />
                          </Heading>
                          <p>
                            Tab size <br /> customization
                          </p>
                        </div>
                      </Level.Item>
                    </Level.Item>
                    <Level.Item>
                      <div>
                        <Heading renderAs="p">
                          <Image
                            alt="Dark and light themes"
                            fixed={
                              this.props.data.darkAndLightThemesImage
                                .childImageSharp.fixed
                            }
                            Tag="span"
                          />
                        </Heading>
                        <p>
                          Dark and light themes for <br />
                          all supported languages
                        </p>
                      </div>
                      <Level.Item>
                        <div>
                          <Heading renderAs="p">
                            <Image
                              alt="Showing of line numbers"
                              fixed={
                                this.props.data.lineNumImage.childImageSharp
                                  .fixed
                              }
                              Tag="span"
                            />
                          </Heading>
                          <p>
                            Showing of <br /> line numbers
                          </p>
                        </div>
                      </Level.Item>
                    </Level.Item>
                    <Level.Item>
                      <div>
                        <Heading renderAs="p">
                          <Image
                            alt="Display invisible characters"
                            fixed={
                              this.props.data.invCharsImage.childImageSharp
                                .fixed
                            }
                            Tag="span"
                          />
                        </Heading>
                        <p>
                          Display invisible
                          <br /> characters
                        </p>
                      </div>
                      <Level.Item>
                        <div>
                          <Heading renderAs="p">
                            <Image
                              alt="Insertion of spaces instead of tabs"
                              fixed={
                                this.props.data.tabSpaceImage.childImageSharp
                                  .fixed
                              }
                              Tag="span"
                            />
                          </Heading>
                          <p>
                            Insertion of spaces <br /> instead of tabs
                          </p>
                        </div>
                      </Level.Item>
                    </Level.Item>
                    <Level.Item>
                      <div>
                        <Heading renderAs="p">
                          <Image
                            alt="Font customization"
                            fixed={
                              this.props.data.fontImage.childImageSharp.fixed
                            }
                            Tag="span"
                          />
                        </Heading>
                        <p>
                          Font
                          <br /> customization
                        </p>
                      </div>
                      <Level.Item>
                        <div>
                          <Heading renderAs="p">
                            <Image
                              alt="GitHub Flavored Markdown"
                              fixed={
                                this.props.data.markdownImage.childImageSharp
                                  .fixed
                              }
                              Tag="span"
                            />
                          </Heading>
                          <p>
                            GitHub Flavored Markdown <br /> with preview mode
                          </p>
                        </div>
                      </Level.Item>
                    </Level.Item>
                  </Level>
                </Box>
              </Section>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero color="white">
          <Hero.Body>
            <Container className="has-text-centered">
              <Heading size={1} renderAs="h2">
                Designed to support multiple storage locations
              </Heading>
              <Heading
                subtitle
                size={5}
                renderAs="h5"
                className="custom-header"
                css={{
                  width: '90%',
                  margin: '0 auto'
                }}
              >
                Store your snippets and notes wherever you want. SnipperApp 2 is
                the offline-first application, and it allows you to work with
                the iCloud and GitHub storage even offline. All changes will be
                synced to iCloud and GitHub once you are back online
              </Heading>
              <div css={{ maxWidth: '75%', margin: '0 auto' }}>
                <Image fluid={this.getImage('schemeDesktop')} />
              </div>
              <Columns style={{ marginTop: '3rem' }} breakpoint="mobile">
                <Columns.Column narrow size={4}>
                  <div css={{ marginRight: '1.5rem' }}>
                    <Image
                      alt="Local storage"
                      fixed={this.props.data.localImage.childImageSharp.fixed}
                      Tag="span"
                    />
                    <p css={{ width: '70%', margin: '0 auto' }}>
                      <FeatureSubheaderTitle>Local</FeatureSubheaderTitle>
                      <FeatureSubheaderSubTitle>
                        Use Local storage to store your snippets only on the
                        current device
                      </FeatureSubheaderSubTitle>
                    </p>
                  </div>
                </Columns.Column>
                <Columns.Column narrow size={4}>
                  <div css={{ marginLeft: '.2rem' }}>
                    <Image
                      alt="iCloud storage"
                      fixed={this.props.data.icloudImage.childImageSharp.fixed}
                      Tag="span"
                    />
                    <p css={{ width: '70%', margin: '0 auto' }}>
                      <FeatureSubheaderTitle>iCloud</FeatureSubheaderTitle>
                      <FeatureSubheaderSubTitle>
                        Use iCloud to keep all your notes stored securely in the
                        cloud and updated everywhere *
                      </FeatureSubheaderSubTitle>
                      {/*<div
                        className="is-size-7 has-text-grey has-text-left"
                        style={{ marginTop: '.5rem' }}
                      >
                        iCloud storage uses a private database. Only the
                        user can access this data through a device
                        authenticated with their iCloud account.
                      </div>*/}
                    </p>
                  </div>
                </Columns.Column>
                <Columns.Column narrow size={4}>
                  <div css={{ marginLeft: '1.5rem' }}>
                    <Image
                      alt="GitHub storage"
                      fixed={this.props.data.githubImage.childImageSharp.fixed}
                      Tag="span"
                    />
                    <p css={{ width: '70%', margin: '0 auto' }}>
                      <FeatureSubheaderTitle>GitHub</FeatureSubheaderTitle>
                      <FeatureSubheaderSubTitle>
                        Use GitHub Gist storage to sync and easily manage your
                        gists from SnipperApp 2
                      </FeatureSubheaderSubTitle>
                    </p>
                  </div>
                </Columns.Column>
              </Columns>

              {/*<Columns style={{ marginTop: '3rem' }}>
                <Columns.Column narrow size={6}>
                  <Image fluid={this.getImage('catalogImage')} />
                </Columns.Column>
                <Columns.Column narrow size={5}>
                  <Media style={{ marginLeft: '5rem' }}>
                    <Media.Item renderAs="figure" position="left">
                      <Image
                        alt="Local storage"
                        fixed={this.props.data.localImage.childImageSharp.fixed}
                        Tag="span"
                      />
                    </Media.Item>
                    <Media.Item>
                      <Content>
                        <p>
                          <strong>Local</strong>
                          <br />
                          Use Local storage to store your snippets only on the
                          current device
                        </p>
                      </Content>
                    </Media.Item>
                  </Media>
                  <Media style={{ marginLeft: '5rem' }}>
                    <Media.Item renderAs="figure" position="left">
                      <Image
                        alt="iCloud storage"
                        fixed={
                          this.props.data.icloudImage.childImageSharp.fixed
                        }
                        Tag="span"
                      />
                    </Media.Item>
                    <Media.Item>
                      <Content>
                        <p>
                          <strong>iCloud</strong>
                          <br />
                          Use iCloud to keep all your notes stored securely in
                          the cloud and updated everywhere
                          <div
                            className="is-size-7 has-text-grey has-text-left"
                            style={{ marginTop: '.5rem' }}
                          >
                            iCloud storage uses a private database. Only the
                            user can access this data through a device
                            authenticated with their iCloud account.
                          </div>
                        </p>
                      </Content>
                    </Media.Item>
                  </Media>
                  <Media style={{ marginLeft: '5rem' }}>
                    <Media.Item renderAs="figure" position="left">
                      <Image
                        alt="GitHub storage"
                        fixed={
                          this.props.data.githubImage.childImageSharp.fixed
                        }
                        Tag="span"
                      />
                    </Media.Item>
                    <Media.Item>
                      <Content>
                        <p>
                          <strong>GitHub</strong>
                          <br />
                          Use GitHub Gist storage to sync and easily manage your
                          gists from SnipperApp 2.
                        </p>
                      </Content>
                    </Media.Item>
                  </Media>
                </Columns.Column>
              </Columns>*/}
            </Container>
          </Hero.Body>
        </Hero>
        <Hero>
          <Hero.Body>
            <Container className="has-text-centered">
              <Heading size={1} renderAs="h2">
                Even more
              </Heading>
              <Heading
                subtitle
                size={6}
                renderAs="h5"
                className="custom-header"
                css={{
                  width: '70%',
                  margin: '0 auto'
                }}
              >
                Extra features to boost your productivity to the new level
              </Heading>
              <Columns style={{ marginTop: '3rem' }} breakpoint="mobile">
                <Columns.Column narrow size={4}>
                  <Image
                    alt="Attach images"
                    fluid={this.getImage('featureImage')}
                  />
                  <p css={{ width: '70%', margin: '0 auto' }}>
                    <FeatureSubheaderTitle>Attach images</FeatureSubheaderTitle>
                    <FeatureSubheaderSubTitle>
                      Use Drag-n-drop or ⌘+V to paste images from the clipboard
                      to your Text or Markdown notes
                    </FeatureSubheaderSubTitle>
                  </p>
                </Columns.Column>
                <Columns.Column narrow size={4}>
                  <Image
                    alt="Menubar app"
                    fluid={this.getImage('featureMenubar')}
                  />
                  <p css={{ width: '70%', margin: '0 auto' }}>
                    <FeatureSubheaderTitle>Menubar app</FeatureSubheaderTitle>
                    <FeatureSubheaderSubTitle>
                      Quickly access your snippets via menubar app. Keyboard
                      shortcuts are supported too
                    </FeatureSubheaderSubTitle>
                  </p>
                </Columns.Column>
                <Columns.Column narrow size={4}>
                  <Image alt="Backups" fluid={this.getImage('featureBackup')} />
                  <p css={{ width: '70%', margin: '0 auto' }}>
                    <FeatureSubheaderTitle>Backups</FeatureSubheaderTitle>
                    <FeatureSubheaderSubTitle>
                      Backup and restore your snippets in one touch
                    </FeatureSubheaderSubTitle>
                  </p>
                </Columns.Column>
              </Columns>
            </Container>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, quality: 95) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    snipperLogo: file(
      absolutePath: { regex: "/snipper-logo-512×512@2x.png/" }
    ) {
      ...fluidImage
    }
    appLight: file(absolutePath: { regex: "/app-v211-light.png/" }) {
      ...fluidImage
    }
    appDark: file(absolutePath: { regex: "/app-v211-dark.png/" }) {
      ...fluidImage
    }
    catalogEditorDark: file(absolutePath: { regex: "/app-v2-editor.png/" }) {
      ...fluidImage
    }
    backgroundImage: file(absolutePath: { regex: "/bg-image@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 2800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    languagesCountImage: file(absolutePath: { regex: "/120@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    darkAndLightThemesImage: file(absolutePath: { regex: "/theme@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    invCharsImage: file(absolutePath: { regex: "/inv-chars@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fontImage: file(absolutePath: { regex: "/font@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    tabCustImage: file(absolutePath: { regex: "/tab-cust@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    lineNumImage: file(absolutePath: { regex: "/line-num@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    tabSpaceImage: file(absolutePath: { regex: "/tab-space@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    markdownImage: file(absolutePath: { regex: "/markdown@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    catalogImage: file(absolutePath: { regex: "/screen-2@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 525, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    localImage: file(absolutePath: { regex: "/local@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    icloudImage: file(absolutePath: { regex: "/icloud@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    githubImage: file(absolutePath: { regex: "/github@2x.png/" }) {
      childImageSharp {
        fixed(width: 71, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    schemeDesktop: file(absolutePath: { regex: "/scheme_desktop@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1308, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    featureImage: file(absolutePath: { regex: "/feature-1@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 598, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    featureMenubar: file(absolutePath: { regex: "/feature-2@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 598, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    featureBackup: file(absolutePath: { regex: "/feature-3@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 598, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
